import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from '../../user/user.model';

@Pipe({
  name: 'pharUserById',
  standalone: true,
})

export class UserByIdPipe implements PipeTransform {
  transform(userId: number, users: UserModel[], displayField = 'userName'): any {
    const user = users.find((u: UserModel) => u.userId === userId);
    return user && user[displayField] ? user[displayField] : 'N/A';
  }
}
